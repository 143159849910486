import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { requiresAuth: true },
    // beforeEnter: (to, from, next) => {
    //   if (store.state.userInfo.role_id == 20) {
    //     next();
    //   } else {
    //     next("order");
    //   }
    // },
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Home.vue'),
  },
  {
    path: '/news',
    name: 'News',
    meta: { requiresAuth: true },
    // beforeEnter: (to, from, next) => {
    //   if (store.state.userInfo.role_id == 20) {
    //     next();
    //   } else {
    //     next("/");
    //   }
    // },
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/News.vue'),
  },
  {
    path: '/news/:id',
    name: 'NewsContent',
    meta: { requiresAuth: true },
    // beforeEnter: (to, from, next) => {
    //   if (store.state.userInfo.role_id == 20) {
    //     next();
    //   } else {
    //     next("/");
    //   }
    // },
    component: () => import(/* webpackChunkName: "home" */ '@/components/home/News_content.vue'),
  },
  {
    path: '/calendar',
    name: 'Calendar',
    meta: { requiresAuth: true },
    // beforeEnter: (to, from, next) => {
    //   if (store.state.userInfo.role_id == 20) {
    //     next();
    //   } else {
    //     next("/");
    //   }
    // },
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Calendar.vue'),
  },
  {
    path: '/calendar/:id',
    name: 'CalendarContent',
    meta: { requiresAuth: true },
    // beforeEnter: (to, from, next) => {
    //   if (store.state.userInfo.role_id == 20) {
    //     next();
    //   } else {
    //     next("/");
    //   }
    // },
    component: () => import(/* webpackChunkName: "home" */ '@/components/home/Calendar_content.vue'),
  },
  {
    path: '/account',
    name: 'Account',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/Account.vue'),
  },
  {
    path: '/order',
    name: 'Order',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/Order.vue'),
  },
  {
    path: '/customerlist',
    name: 'CustomerList',
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.userInfo.role_id == 20) {
        next();
      } else {
        next('/');
      }
    },
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/CustomerList.vue'),
  },
  {
    path: '/identification',
    name: 'Identification',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/Identification.vue'),
  },
  {
    path: '/classbarcode',
    name: 'ClassBarCode',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "user" */ '@/views/user/ClassBarCode.vue'),
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "home" */ '@/views/home/Privacy.vue'),
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/Login.vue'),
  },
  {
    path: '/logout',
    name: 'Logout',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/Logout.vue'),
  },
  {
    path: '/verifyemail',
    name: 'Verifyemail',
    props: (route) => ({ query: route.query.token }),
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/Verifyemail.vue'),
  },
  {
    path: '/signup',
    name: 'Signup',
    component: () => import(/* webpackChunkName: "login" */ '@/views/login/Signup.vue'),
  },
  {
    path: '/resell',
    name: 'Resell',
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.userInfo.role_id == 20) {
        next();
      } else {
        next('/');
      }
    },
    component: () => import(/* webpackChunkName: "store" */ '@/views/store/Resell.vue'),
  },
  {
    path: '/resell/:id',
    name: 'Resell_Product',
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.userInfo.role_id == 20) {
        next();
      } else {
        next('/');
      }
    },
    component: () => import(/* webpackChunkName: "store" */ '@/views/store/Product.vue'),
  },
  {
    path: '/vip_store',
    name: 'Vip_Store',
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.userInfo.role_id == 20) {
        next();
      } else {
        next('/');
      }
    },
    component: () => import(/* webpackChunkName: "store" */ '@/views/store/Vip_Store.vue'),
  },
  {
    path: '/vip_store/:id',
    name: 'Vip_Store_Product',
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.userInfo.role_id == 20) {
        next();
      } else {
        next('/');
      }
    },
    component: () => import(/* webpackChunkName: "store" */ '@/views/store/Product.vue'),
  },
  {
    path: '/shoppingcart',
    name: 'ShoppingCart',
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.userInfo.role_id == 20) {
        next();
      } else {
        next('/');
      }
    },
    component: () => import(/* webpackChunkName: "shopping" */ '@/views/shopping/ShoppingCart.vue'),
  },
  {
    path: '/checkout',
    name: 'Checkout',
    meta: { requiresAuth: true },
    beforeEnter: (to, from, next) => {
      if (store.state.userCart.length >= 1) {
        next();
      } else {
        next('/');
      }
    },
    component: () => import(/* webpackChunkName: "shopping" */ '@/views/shopping/Checkout.vue'),
  },
  {
    path: '/shoppingcart/:cart_id',
    name: 'Checkout_ShopCart',
    component: () => import(/* webpackChunkName: "shopping" */ '@/views/shopping/Checkout_shopcart.vue'),
  },
  {
    path: '/checkout/:cart_id',
    name: 'Checkout_cart',
    component: () => import(/* webpackChunkName: "shopping" */ '@/views/shopping/Checkout_shopcart.vue'),
  },
  {
    path: '/checkout/result/:order_id',
    name: 'Checkout_Result',
    component: () => import(/* webpackChunkName: "shopping" */ '@/views/shopping/Result.vue'),
  },
  {
    path: '/complete',
    name: 'Complete',
    component: () => import(/* webpackChunkName: "shopping" */ '@/views/shopping/Complete.vue'),
  },
  {
    path: '/false',
    name: 'False',
    component: () => import(/* webpackChunkName: "shopping" */ '@/views/shopping/False.vue'),
  },
  {
    path: '/forgetpassword',
    name: 'ForgetPassword',
    component: () => import(/* webpackChunkName: "login" */ '@/views/user/ForgetPassword.vue'),
  },
  {
    path: '/forgetuserid',
    name: 'ForgetUserId',
    component: () => import(/* webpackChunkName: "login" */ '@/views/user/ForgetUserId.vue'),
  },
  {
    path: '/changepassword',
    name: 'ChangePassword',
    meta: { requiresAuth: true },
    component: () => import(/* webpackChunkName: "login" */ '@/views/user/ChangePassword.vue'),
  },
  {
    path: '*',
    redirect: '/',
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});
router.beforeEach((to, from, next) => {
  const isAuthenticated = !!store.state.token; // 判斷是否已登入
  const publicPages = ['Login', 'Signup', 'ForgetPassword', 'ForgetUserId']; // 不需要登入的頁面名稱

  if (isAuthenticated && publicPages.includes(to.name)) {
    // 如果已登入，且嘗試進入不需要登入的頁面
    next({ path: '/' }); // 將用戶重定向到首頁
  } else if (to.matched.some((record) => record.meta.requiresAuth)) {
    // 如果目標頁面需要身份驗證
    if (!isAuthenticated) {
      // 如果未登入，重定向到登入頁面
      next({
        path: '/login',
        query: { redirect: to.fullPath }, // 保存目標路徑，方便登入後重定向
      });
    } else if (['False', 'Complete'].includes(to.name)) {
      // 如果目標頁面是 'False' 或 'Complete'，允許訪問
      next();
    } else {
      // 如果已登入且符合條件，允許繼續
      next();
    }
  } else {
    // 如果目標頁面不需要身份驗證
    next();
  }
});

// router.beforeEach((to, from, next) => {
//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     // this route requires auth, check if logged in
//     // if not, redirect to login page.
//     if (to.name == 'False' || to.name == 'Complete') {
//       next();
//     }
//     if (!store.state.token) {
//       if (from.name != '/login') {
//         next({
//           path: '/login',
//           query: { redirect: to.fullPath },
//         });
//       }
//     } else {
//       next();
//     }
//   } else {
//     next(); // make sure to always call next()!
//   }
// });

export default router;
